import React from "react";
import { useForm } from "react-hook-form";
import { message } from 'antd';
import RestClient from '../utilities/RestClient';
const key = 'updatable';

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {

    message.loading({ content: 'Sending you message . . .', key, duration: 0 });

    setTimeout(() => {
      let url = process.env.REACT_APP_API_URL + '/contact-me';

      RestClient.post(url, data)
        .then(response => {
          if(response.statusCode === 200){
            e.target.reset();
            reset({name: "", email: "", subject: "", message: ""});
            message.success({ content: 'Message sent successfully!', key, duration: 3 });
          }
          else if(response.statusCode === 422){
            message.error({ content: response.message, key, duration: 3 });
          }
          else {
            message.error({ content: 'Unable to process your request, Plase try again later.', key, duration: 3 });
          }
        })
        .catch(error => {
          message.error({ content: 'Unable to process your request, Plase try again later.', key, duration: 3 });
        });
    }, 1500);
  };

  return (
    <>
      <form className="contactform" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                {...register("name", { required: true })}
                type="text"
                name="name"
                placeholder="YOUR NAME"
              />
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
                type="email"
                name="email"
                placeholder="YOUR EMAIL"
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-12">
            <div className="form-group">
              <input
                {...register("subject", { required: true })}
                type="text"
                name="subject"
                placeholder="YOUR SUBJECT"
              />
              {errors.subject && (
                <span className="invalid-feedback">Message is required.</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                {...register("message", { required: true })}
                name="message"
                placeholder="YOUR MESSAGE"
              ></textarea>
              {errors.message && (
                <span className="invalid-feedback">Message is required.</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <button type="submit" className="button">
              <span className="button-text">Send Message</span>
              <span className="button-icon fa fa-send"></span>
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>

      {/* End contact */}
    </>
  );
};

export default Contact;
