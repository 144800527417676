import React from "react";

const educationContent = [
  // {
  //   year: "2015",
  //   degree: "ENGINEERING DEGREE",
  //   institute: "OXFORD UNIVERSITY",
  //   details: `  Lorem ipsum dolor sit amet, consectetur tempor incididunt ut labore
  //       adipisicing elit`,
  // },
  {
    year: "2021",
    degree: "PG Diploma",
    institute: "PANJAB UNIVERSITY, CHANDIGARH",
    details: `I completed a post-graduate diploma in computer application from Panjab University, Chandigarh, during the session of 2019-2021, and achieved 72.30% of total marks. Throughout the course, I delved deep into various aspects of computer science.`,
  },
  {
    year: "2014",
    degree: "BACHELOR DEGREE ",
    institute: "M.J.P.R. UNIVERSITY, BAREILLY",
    details: `I graduated with a Bachelor of Science degree from Vardhman College, Bijnor, affiliated with M.J.P. Rohilkhand University, Bareilly, Uttar Pradesh, in July 2014, and achieved 61.48% of total marks.`,
  },
  {
    year: "2011",
    degree: "INTERMEDIATE",
    institute: "STATE BOARD OF UTTAR PRADESH",
    details: `I completed my Intermediate exams from the State Board of Uttar Pradesh in 2011. I pursued the science stream and successfully passed the examination.`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
