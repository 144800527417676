import React from "react";

const experienceContent = [
  {
    year: "  MAY 2019 - Present",
    position: " Sr. Software Engineer",
    compnayName: "iTechnoLabs",
    details: `After two years of dedicated work and continuous growth in my role, I sought a new environment to further expand my skills. Consequently, I transitioned to iTechnoLabs, a subsidiary of YapApp India Pvt. Ltd., where I assumed the role of a software engineer.`,
  },
  {
    year: "  JANUARY 2017 - APRIL 2019",
    position: " Jr. Web Developer",
    compnayName: "DUSKBYTE",
    details: `In my role as a junior web developer at DuskByte, I was tasked with writing efficient code and designing website layouts in alignment with the company's specifications. Additionally, I managed the deployment of application code across the web server.`,
  },
  {
    year: "  SEPTEMBER 2014 - MARCH 2015",
    position: " Internship",
    compnayName: "DUCAT TECHNOLOGIES",
    details: `After completing my bachelor's degree, I undertook a six-month internship at Ducat Technologies, Noida.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
