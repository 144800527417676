import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {NextArrow, PrevArrow} from './SliderArrows'

const PortfolioContent = (props) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>{props.projectTitle}</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-o pr-2"></i>
              <span className="ft-wt-600 project-label">Project </span>:{" "}
              <span className="uppercase">{props.projectType}</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="ft-wt-600 project-label">Preview </span>:{" "}
              <span className="uppercase">
                {props.previewUrl ?
                  <a
                    href={props.previewUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.previewTitle}
                  </a>
                  :
                  props.previewTitle
                }
                  
              </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-12 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="ft-wt-600 project-label">Technologies </span>:{" "}
              <span className="uppercase">{props.technologies}</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-12 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="ft-wt-600 project-label">Description </span>:{" "}
              <span className="uppercase">{props.description}</span>
            </div>
            {/* End .col */}
            
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}
        
        {/*  Main Project Content Starts */}
        {props.imageType === "slider" && props.images && props.images.length > 0 ?
          <Slider {...settings}>
            { props.images.map((imageName, index) => {
                return (
                  <div className="slide_item" key={index}>
                    <img src={"img/projects/"+imageName} alt={"slide " + (index+1)}/>
                  </div>
                ) 
              })
            }
          </Slider>
          :
          ""
        }

        {props.imageType === "image" && props.modalImage ?
          <img src={"img/projects/"+props.modalImage} alt="Portolio" />
          :
          ""
        }
        {/* Main Project Content Ends */}
      </figure>
    </div>
    //  End .slideshow
  );
};

export default PortfolioContent;
