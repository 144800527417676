import React, { useState } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import Index from "../about/index";

const heroContent = {
  heroImage: "img/hero/devendra.png",
  heroMobileImage: "img-mobile-light",
  heroTitleName: "Devendra K",
  heroDesignation: "Full-Stack Developer",
  // heroDescriptions: `I'm a Tunisian based web designer & front‑end developer focused on
  // crafting clean & user‑friendly experiences, I am passionate about
  // building excellent software that improves the lives of those
  // around me.`,
  heroDescriptions: `I am a full-stack developer, dedicated to crafting clean and user-friendly experiences. My passion lies in creating exceptional web applications that enhance the lives of users. I am eager to contribute as a key player in a challenging and innovative environment. With a knack for meeting deadlines, I am capable of delivering high-quality projects`,
  heroBtn: "more about me",
};

const HeroLight = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="row home-details-container align-items-center">
        <div
          className="col-lg-4 bg position-fixed d-none d-lg-block"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + heroContent.heroImage
            })`,
          }}
        ></div>
        <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
          <div>
            <img
              src={`img/hero/${heroContent.heroMobileImage}.png`}
              className="img-fluid main-img-mobile d-sm-block d-lg-none"
              alt="hero man"
            />
            <h1 className="text-uppercase poppins-font">
              I'm {heroContent.heroTitleName}.
              <span className="hero-designation">{heroContent.heroDesignation}</span>
            </h1>
            <p className="open-sans-font">{heroContent.heroDescriptions}</p>
            {/* <button className="button" onClick={toggleModalOne}>
              <span className="button-text">{heroContent.heroBtn}</span>
              <span className="button-icon fa fa-arrow-right"></span>
            </button> */}
            <button className="button" onClick={() => {return history.push('/about')}}>
              <span className="button-text">{heroContent.heroBtn}</span>
              <span className="button-icon fa fa-arrow-right"></span>
            </button>
          </div>
        </div>
      </div>
      {/* End home-details-container */}

      {/* Start Modal for About More */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal dark light"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner about green">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section text-left text-sm-center">
                <h1>
                  ABOUT <span>ME</span>
                </h1>
                <span className="title-bg">Resume</span>
              </div>
              {/* End title */}
              <Index />
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for About More */}
    </>
  );
};

export default HeroLight;
