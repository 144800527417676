import React from "react";
import Tile from "./Tile"

const GalleryLight = () => {
  
  const portfolioData = [
    {
      id: 1,
      name: "Employee of the month",
      image: "img/gallery/employee_of_the_month.jpg"
    },
    {
      id: 2,
      name: "EOM Certificate",
      image: "img/gallery/eom_certificate.JPG"
    },
    {
      id: 3,
      name: "Reward",
      image: "img/gallery/reward.jpg"
    },
    {
      id: 4,
      name: "Appreciation 1",
      image: "img/gallery/appreciation_1.jpg"
    },
    {
      id: 5,
      name: "Appreciation 2",
      image: "img/gallery/appreciation_2.jpg"
    },
    {
      id: 6,
      name: "Appreciation 3",
      image: "img/gallery/appreciation_3.jpg"
    },
    {
      id: 7,
      name: "Appreciation 4",
      image: "img/gallery/appreciation_4.jpg"
    },
    {
      id: 8,
      name: "Collegue",
      image: "img/gallery/collegue_1.jpg"
    },
    {
      id: 9,
      name: "Collegue 2",
      image: "img/gallery/collegue_2.jpg"
    },
    // {
    //   id: 10,
    //   name: "Flowers",
    //   image: "img/gallery/collegue_3.jpg"
    // },
    // {
    //   id: 11,
    //   name: "Flowers",
    //   image: "img/gallery/jr_developer.jpg"
    // },
    // {
    //   id: 12,
    //   name: "Flowers",
    //   image: "img/gallery/devendra-orignal.jpg"
    // }
  ];

  return (
    <>
      {/* <!-- Gallery Item Starts --> */}
      <div className="tiles">
        {/* <div className="row"> */}
          {portfolioData.map((item) => {
            return (
              // <div className="col-3">
                <Tile data={item} key={item.id} />
              // </div>
            )
          })}
        {/* </div> */}
      </div>
      {/* <!-- Gallery Item Ends --> */}
    </>
  );
};

export default GalleryLight;
