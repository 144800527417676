import * as TYPE from '../../constants/actionTypes'

const initialState = {
    blogsData: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case TYPE.ADD_BLOGS:
            return {...state, blogsData: action.data}
            // break;
    
        default:
            return state
            // break;
    }
}