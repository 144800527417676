// import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { encryptTransform } from 'redux-persist-transform-encrypt';


import reducers from "./reducers";

// import mainReducer from "./reducers/MainReducer.js";
// import constsReducer from "./reducers/ConstsReducer";

// import user from "./reducers/user";
// import notificationMessage from "./reducers/notificationMessage";
// import overlaySpinner from "./reducers/overlaySpinner";
// import updateManagement from "./reducers/updateManagement";
// import findStuff from "./reducers/findStuff";
// import viewer from "./reducers/viewer";
// import admin from "./reducers/admin";

const middleware = [thunk, logger];

const encryptor = encryptTransform({
    secretKey: 'jWnZr4u7x!A%D*F-JaNdRgUkXp2s5v8y',
    onError: function (error) {
        console.log(error,"======error");
    },
  });
  
const persistConfig = {
    key: 'react-cart',
    transforms: [encryptor],
    blacklist: ['isLoading', 'temp'],
    storage
  };

const rootReducer = combineReducers(reducers);
  
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    middleware: middleware,
    reducer: persistedReducer,
});

const persistor = persistStore(store);

export {
    store,
    persistor
}
