import React from "react";

const personalInfoContent = [
  { meta: "First Name", metaInfo: "Devendra K." },
  { meta: "Last Name", metaInfo: "Rajput" },
  { meta: "Age", metaInfo: "29 Years" },
  { meta: "Nationality", metaInfo: "Indian" },
  // { meta: "Freelance", metaInfo: "Available" },
  { meta: "Region", metaInfo: "Chandigarh, India" },
  { meta: "Phone", metaInfo: "+91-9557747567" },
  { meta: "Email", metaInfo: "developer@devrajput.in" },
  // { meta: "Skype", metaInfo: " steve.milner" },
  { meta: "Languages", metaInfo: "Hindi, English" },
  { meta: "Passion", metaInfo: "Learning about history" },
  { meta: "Favorite Sport", metaInfo: "Cricket" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
