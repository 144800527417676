import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Achievements from "./Achievements";
import Education from "./Education";
import Experience from "./Experience";
import PersonalInfo from "./PersonalInfo";
import Skills from "./Skills";
import Modal from "react-modal";
import { message } from 'antd';
import RestClient from '../../utilities/RestClient';
const key = 'updatable';

const Index = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggleModal() {
    reset({name: ""});
    setIsModalOpen(!isModalOpen);
  }

  const onSubmit = (data, e) => {
    // console.log(data, "=====data");
    message.loading({ content: 'Downloading . . .', key, duration: 0 });
    setTimeout(() => {
      let url = process.env.REACT_APP_API_URL + '/download-cv';

      RestClient.post(url, data)
        .then(response => {
          if(response.statusCode === 200){
            const url = 'img/Devendra_Kumar_Resume.pdf';
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `Devendra_Kumar_Resume.pdf`
            );
            document.body.appendChild(link);
            link.click();

            e.target.reset();
            reset({name: ""});
            toggleModal();
            message.success({ content: "It's downloaded!", key, duration: 3 });
          }
          else {
            message.error({ content: 'Unable to process your request, Plase try again later.', key, duration: 3 });
          }
        })
        .catch(error => {
          message.error({ content: 'Unable to process your request, Plase try again later.', key, duration: 3 });
        });
    }, 1500);
  }
  return (
    <section className="main-content ">
      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="custom-modal dark green"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModal}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner portfolio download-cv">
            <div className="slideshow contact">
              <figure>
                {/* Project Details Starts */}
                <figcaption>
                  <h3>GET MY RESUME</h3>
                  <form className="contactform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row open-sans-font">
                      <div className="col-12 mb-2">
                        <div className="form-group">
                          <input
                            {...register("name", { required: true })}
                            type="text"
                            name="name"
                            placeholder="Enter your name here . . ."
                          />
                          {errors.name && errors.name.type === "required" && (
                            <span className="invalid-feedback" style={{marginLeft: "1.75rem", fontWeight: 600}}>Name is required</span>
                          )}
                        </div>
                      </div>

                      {/* End .col */}
                      <div className="col-12 text-center">
                        <button type="submit" className="button">
                          <span className="button-text">Download Now</span>
                          <span className="button-icon fa fa-download"></span>
                        </button>
                      </div>
                      {/* End .col */}
                    </div>
                  </form>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </Modal>
      <div className="container">
        <div className="row">
          {/* Personal Info Starts */}
          <div className="col-xl-6 col-lg-5 col-12">
            <div className="row">
              {/* <div className="col-12">
                <h3 className="text-uppercase custom-title mb-0 ft-wt-600">
                  personal infos
                </h3>
              </div> */}
              {/* End .col */}

              <div className="col-12 d-block d-sm-none">
                <img
                  src="img/hero/img-mobile.png"
                  className="img-fluid main-img-mobile"
                  alt="about avatar"
                />
              </div>
              {/* image for mobile menu */}

              <div className="col-12">
                <PersonalInfo />
              </div>
              {/* End personal info */}

              <div className="col-12 mt-1">
                {/* <a className="button" href="img/Devendra_Kumar_Resume.pdf" download>
                  <span className="button-text">Download CV</span>
                  <span className="button-icon fa fa-download"></span>
                </a> */}
                <button className="button" onClick={() => {toggleModal()}}>
                  <span className="button-text">Download CV</span>
                  <span className="button-icon fa fa-download"></span>
                </button>
              </div>
              {/* End download button */}
            </div>
          </div>
          {/*  Personal Info Ends */}

          {/*  Boxes Starts */}
          <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
            <Achievements />
          </div>
          {/* Achievements Ends */}
        </div>
        {/* End .row */}

        <hr className="separator" />

        {/* Skills Starts */}
        <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600">
              My Skills
            </h3>
          </div>
          <Skills />
        </div>
        {/* Skills Ends */}

        <hr className="separator mt-1" />

        {/* Experience & Education Starts */}
        <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
              Experience <span>&</span> Education
            </h3>
          </div>
          <div className="col-lg-6 m-15px-tb">
            <div className="resume-box">
              <Experience />
            </div>
          </div>
          <div className="col-lg-6 m-15px-tb">
            <div className="resume-box">
              <Education />
            </div>
          </div>
        </div>
        {/*  Experience & Education Ends */}
      </div>
    </section>
  );
};

export default Index;
