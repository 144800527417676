import querystring from 'querystring';
import axios from 'axios';

// import { getDeviceInfo } from '../App/Lib/Utils';


/******** Set Authorization token in header ***********/
export const setAuthorizationToken = (axios, token) => {
  if (token) {
    //axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common.Authorization = token;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

var config = {
  headers: { 'Content-Type': 'application/json;charset=utf-8', 'Accept':'application/json' }
};

class RestClient {
  static post(url, params, token = '', fingerprint=false) {
   setAuthorizationToken(axios, token);
    return new Promise(function(fulfill, reject) {
      axios
        .post(url, JSON.stringify(params), config)
        .then(function(response) {
          fulfill({statusCode: response.status, data:response.data});
        })
        .catch(function(error) {
          if (error && error.response) {
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  static put(url, params, token = '') {
    setAuthorizationToken(axios, token);
    return new Promise(function(fulfill, reject) {
      axios
        .put(url, JSON.stringify(params), config)
        .then(function(response) {
          fulfill(response.data);
        })
        .catch(function(error) {
          if (error && error.response) {
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  static delete(url, token = '') {
    setAuthorizationToken(axios, token);
    return new Promise(function(fulfill, reject) {
      axios
        .delete(url, config)
        .then(function(response) {
          fulfill(response.data);
        })
        .catch(function(error) {
          if (error && error.response) {
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  static get(url, params, token = '', fingerprint=false) {
    let query = querystring.stringify(params);
    setAuthorizationToken(axios, token);
    return new Promise(function(fulfill, reject) {
      axios
        .get(url + '?' + query, config)

        .then(function(response) { 
          fulfill({status: response.status, data:response.data});
        })
        .catch(function(error) {
          if (error && error.response) {
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  /*************** Form-Data Method ***********/
  static postFormData(url, params, token = '') {
    setAuthorizationToken(axios, token);
    config.headers['Content-Type'] = 'multipart/form-data';
    return new Promise(function(fulfill, reject) {
      // var body = new FormData();
      // body.append('attachment', params);
      axios
        .post(url, params, config, token)

        .then(function(response) {
          fulfill({ statusCode: response.status, data: response.data });
        })
        .catch(function(error) {
          if (error && error.response) {
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }
  
  /*************** Basic Auth Method ***********/
  static postWithBasicAuth(url, params, auth) {
    // setAuthorizationToken(axios, token);
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    config.auth = auth;
    return new Promise(function(fulfill, reject) {
      // var body = new FormData();
      // body.append('attachment', params);
      axios
        .post(url, params, config)

        .then(function(response) {
          fulfill({ statusCode: response.status, data: response.data });
        })
        .catch(function(error) {
          if (error && error.response) {
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }
}

                // axios.post(url, data, config)
                //   .then(function (response) {
                //     if(response.data && response.data.statusCode === 200){
                //       e.target.reset();
                //       message.success({ content: 'Message sent successfully!', key, duration: 3 });
                //     }
                //   })
                //   .catch(function (error) {
                //     // Error
                //     if (error.response) {
                //         // The request was made and the server responded with a status code
                //         // that falls out of the range of 2xx
                //         // console.log(error.response.data);
                //         // console.log(error.response.status);
                //         // console.log(error.response.headers);
                //         if(error.response.status === 422 && error.response.data && error.response.data.message){
                //           message.error({ content: error.response.data.message, key, duration: 3 });
                //         }
                //         else {
                //           message.error({ content: 'Unable to process your request, Plase try again later.', key, duration: 3 });
                //         }
                //     } else if (error.request) {
                //         // The request was made but no response was received
                //         // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                //         // http.ClientRequest in node.js
                //         // console.log(error.request);
                //         message.error({ content: 'Unable to process your request, Plase try again later.', key, duration: 3 });
                //     } else {
                //         // Something happened in setting up the request that triggered an Error
                //         // console.log('Error', error.message);
                //         message.error({ content: 'Unable to process your request, Plase try again later.', key, duration: 3 });
                //     }
                //   });

                
export default RestClient;
