import React from "react";

const SocialShare = [
  {
    slug: "linkedin",
    iconName: "fa fa-linkedin",
    link: "https://www.linkedin.com/in/devendra-kumar-rajput-3ba793a7",
  },
  // {
  //   slug: "facebook",
  //   iconName: "fa fa-facebook",
  //   link: "https://www.facebook.com/",
  // },
  { 
    slug: "twitter",
    iconName: "fa fa-twitter", 
    link: "https://twitter.com/Devendr77562223" 
  },
  { 
    slug: "stack_overflow",
    iconName: "fa fa-stack-overflow", 
    link: "https://stackoverflow.com/users/8848139/devendra-rajput"
  },
  { 
    slug: "github",
    iconName: "fa fa-github", 
    link: "https://github.com/devendra-rajput"
  },
];

const Social = () => {
  return (
    <ul className="social list-unstyled pt-1 mb-5">
      {SocialShare.map((val, i) => (
        <li key={i}>
          {val.slug === 'twitter' ?
              <a href={val.link} target="_blank" rel="noreferrer" className="twitter-icon">
                <span className="svg-icon"></span>
              </a>
          : 
            <a href={val.link} target="_blank" rel="noreferrer">
              <i className={val.iconName}></i>
            </a>
          }
        </li>
      ))}
    </ul>
  );
};

export default Social;
